<template>
  <section class="user-guide-fourth-step">
    <div class="user-guide__input-wrapper">
      <input
        class="user-guide__input"
        id="product-price"
        type="number"
        min="1"
        :placeholder="$translate('user_guide.step_4.product_price')"
        :value="update_product_price"
        @input="update_product_price = $event.target.value"
      />
      <label
        class="user-guide__label"
        for="product-price"
      >
        {{ currency_sign }}
      </label>
    </div>
    <div
      class="user-guide__input-wrapper"
    >
      <input
        class="user-guide__input"
        type="number"
        min="0"
        id="product-quantity"
        :placeholder="$translate('user_guide.step_4.product_inventory')"
        :value="update_product_inventory"
        @input="update_product_inventory = $event.target.value"
      />
      <label
        class="user-guide__label"
        for="product-quantity"
      >
        {{ $translate("user_guide.step_4.inventory_unit") }}
      </label>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { currency_signs, lang_to_currency_mapper } from "../../../../Shared/constants/other"
import { ADMIN } from "../../../constants/others_constants"

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState(ADMIN, ["admin_language"]),
    update_product_price: {
      get() {
        return this.data.product_price
      },
      set(value) {
        this.$emit("update_value", value, "product_price")
      }
    },
    update_product_inventory: {
      get() {
        return this.data.product_inventory
      },
      set(value) {
        this.$emit("update_value", value, "product_inventory")
      }
    },
    currency_sign() {
      return currency_signs[lang_to_currency_mapper[this.admin_language]]
    }
  },
}
</script>
